import { createTheme }  from '@material-ui/core/styles'
const theme = createTheme({
  palette: {
    primary: { main: '#fffff', },
    secondary: { A400: '#82d5ff'},
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#82d5ff',
        },
      },
    },
    MuiFormLabel: {
      root: {
          color: '#000000',
        }
      }
  }
})

export default theme