import React from 'react'
import ProductHero from './ProductHero'
import ProductValues from './ProductValues';
import LargeBox from './LargeBox';
import MediumBox from './MediumBox';
import SmallBox from './SmallBox';

function Home() {
  return (
      <>
      <ProductHero/>
      <ProductValues/>
      <LargeBox/>
      <MediumBox/>
      <SmallBox/>
      </>
  )
}

export default Home