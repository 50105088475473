import React from 'react'
import QuoteFields from './QuoteFields'
import QuoteInfo from './QuoteInfo'
import QuoteProgress from './QuoteProgress'
import LinearStepper from "./LinearStepper";
import { CssBaseline, Container, Paper, Box } from "@material-ui/core";
import QuoteQuestions from './QuoteQuestions';



function QuoteHome() {
  return (
    <>
    <CssBaseline />
    <Container component={Box} p={4}>
        <QuoteQuestions />
    </Container>
  </>
);
}

export default QuoteHome