import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/home/Home';
import NotFound from './components/pages/NotFound';
import Header from './components/navigation/Header';
import CommunityHome from './components/pages/community/CommunityHome';
import QuoteHome from './components/pages/quote/QuoteHome';
import ServicesHome from './components/pages/services/ServicesHome';
import About from './components/pages/About';
import Footer from './components/navigation/Footer';
import Admin from './components/pages/Admin'

export default function App() {


  return (
   <Router>
     <Header/>
     <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/communityhome' exact component={CommunityHome} />
          <Route path='/quotehome' exact component={QuoteHome} />
          <Route path='/serviceshome' exact component={ServicesHome} />
          <Route path='/about' exact component={About} />
          <Route path='/admin' exact component={Admin} />
          <Route default component={NotFound} />
     </Switch>
     <Footer/>
   </Router>
  );
}
