import React, { useState } from "react";
import {
  Typography,
  FormControlLabel,
  Grid,
  Container,
  TextField,
  Checkbox,

} from "@material-ui/core";
import DatePicker from 'react-date-picker';

function QuoteQuestions() {
  const [value, onChange] = useState(new Date());

  return (
    <>
    <FormControlLabel
    control={<Checkbox name="Test" />}
    label="Residential"
  />
     <FormControlLabel
    control={<Checkbox name="Test" />}
    label="Commerical"
  />
     <FormControlLabel
    control={<Checkbox name="Test" />}
    label="Non Profit"
  />
      <TextField
        id="zip-code"
        label="Zip Code"
        variant="outlined"
        placeholder="Enter Your Zip Code"
        fullWidth
        margin="normal"
        name="zipCode"
      />
      <TextField
        id="last-name"
        label="Last Name"
        variant="outlined"
        placeholder="Enter Your Last Name"
        fullWidth
        margin="normal"
        name="lastName"
      /> 

    <DatePicker onChange={onChange} value={value} />                
    <Container maxWidth="xs">
    <Grid 
    container 
    spacing={0}
    alignItems="center"
    justifyContent="center"
    >
   <Grid item xs={12}>
   <Typography>Please select all jobs that you'd like done.</Typography>
   </Grid>
    <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
     <Grid item xs={12} md={6}>
     <FormControlLabel
       control={<Checkbox name="gilad" />}
       label="Gilad Gray"
     />
     </Grid>
   </Grid>
   </Container>
   </>
  )
}

export default QuoteQuestions