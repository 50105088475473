import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@material-ui/core"
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './Theme'
import App from './App';
//import Amplify from 'aws-amplify';
//import awsconfig from './aws-exports';
import '@fontsource/roboto';

//Amplify.configure(awsconfig);


const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
    </ThemeProvider>
  </React.StrictMode>,
  rootElement
  );