import {
    AppBar,
    Container,
    Hidden,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Button,
  } from "@material-ui/core";
  import HideOnScroll from "./HideOnScroll";
  import SideDrawer from "./SideDrawer";
  import { Link } from 'react-router-dom';
  import React from 'react';
  import logo from '../../images/SBWhiteBg.svg';

  

  const useStyles = makeStyles({
    navbarDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`
    },
    navListDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`
    },
    linkText: {
      textDecoration: `none`,
      color: `white`,
    }, 
    logo: {
      maxWidth: 50,
      marginTop: 5,  
    },
    outlinedButton: {
      border: '1px solid #82d5ff',
    },
    outlinedText: {
      color: '#82d5ff',
    },
    icon: {
      color: 'black',
    },
    listItemText: {
      fontSize:'1.4em',//Insert your required size
    },
  });
  
  const navLinks = [
    { title: `About`, path: `/about` },
  ];

  const Header = () => {
    const classes = useStyles();
// Took out <HideOnScroll> place before Appbar and after Appbar but before toolbar
  
    return (
      <>
      <HideOnScroll>
          <AppBar position="fixed" className={classes.appbarRoot} style={{ boxShadow: 'none', backgroundColor: '#095383'}}>
            <Toolbar component="nav">
              <Container maxWidth="lg" className={classes.navbarDisplayFlex}>
                <Link to='/'>
                  <Button edge="start">
                    <img src={logo} alt="Kitty Katty!" className={classes.logo} />
                  </Button>
                </Link>
                <Hidden smDown>
                  <List
                    component="nav"
                    aria-labelledby="main navigation"
                    className={classes.navListDisplayFlex}
                  >
                    <Link to='/communityhome' className={classes.linkText}>
                        <ListItem button>
                          <ListItemText classes={{primary:classes.listItemText}} primary='Community Outreach' />
                        </ListItem>
                    </Link> 
                    <Link to='/quotehome' className={classes.linkText}>
                        <ListItem button>
                          <ListItemText classes={{primary:classes.listItemText}} primary='Quote' />
                        </ListItem>
                    </Link> 
                    <Link to='/serviceshome' className={classes.linkText}>
                        <ListItem button>
                          <ListItemText classes={{primary:classes.listItemText}} primary='Services' />
                        </ListItem>
                    </Link> 
                    <Link to='/about' className={classes.linkText}>
                        <ListItem button>
                          <ListItemText classes={{primary:classes.listItemText}} primary='About Us' />
                        </ListItem>
                    </Link> 
                  </List>
                </Hidden>
                <Hidden mdUp>
                  <SideDrawer navLinks={navLinks} />
                </Hidden>
              </Container>
            </Toolbar>
          </AppBar>
          </HideOnScroll>
        <Toolbar id="back-to-top-anchor" />   
        </>
        
      );
    ;
  };
  
  export default Header;